<template>
  <div class="w-full md:mx-2 rounded">
    <div class="mb-10 bg-white">
      <div
        class="flex justify-between border-b border-gray-300 mb-3 items-center px-2"
      >
        <p class="p-3 font-desain text-[#212121] font-semibold text-lg">
          List Pertanyaan
        </p>
      </div>
      <div class="w-full md:w-3/4 m-auto mb-6">
        <DataTable
          :value="questions"
          :first="paginationQuestion.from"
          :paginator="true"
          :rows="paginationQuestion.perPage"
          dataKey="id"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[10, 25, 50]"
          class="p-datatable-sm font-solusi text-sm border border-gray-200"
          currentPageReportTemplate="Menampilkan {first} hingga {last} dari {totalRecords} pertanyaan"
          :totalRecords="paginationQuestion.total"
          responsiveLayout="scroll"
          :lazy="true"
          :loading="isLoading"
          @page="onPage($event)"
          :rowHover="true"
        >
          <template #empty>
            <div
              class="text-center font-semibold text-[#DE1306] font-solusi text-base"
            >
              Belum ada pertanyaan
            </div>
          </template>
          <template #loading>
            <div class="text-center font-semibold font-solusi text-base">
              Loading data pertanyaan. Please wait.
            </div>
          </template>
          <Column
            field="name"
            header="No."
            headerClass="font-solusi font-semibold w-12"
            bodyClass="font-solusi font-semibold text-sm"
          >
            <template #body="{index}">
              {{ index + paginationQuestion.from }}.
            </template>
          </Column>
          <Column
            field="name"
            header="Nama"
            headerClass="w-48"
            bodyClass="font-solusi font-medium text-sm"
          >
            <template #body="{data}">
              {{ data.user.name }}
            </template>
          </Column>

          <Column
            field="name"
            header="Pertanyaan"
            bodyClass="font-solusi font-medium text-sm"
          >
            <template #body="{data}">
              {{ data.question }}
            </template>
          </Column>

          <Column
            field="name"
            header="File"
            headerClass="w-20"
            bodyClass="font-solusi font-medium text-sm"
          >
            <template #body="{data}">
              <a
                v-if="data.file"
                :href="data.file"
                target="_blank"
                class="font-solusi text-xs py-1.5 px-3 text-center rounded-full text-[#009DFF] border border-blue-400 font-semibold flex items-center hover:text-white hover:bg-[#009DFF] duration-300"
              >
                <svg
                  class="w-4 h-4 mr-2"
                  width="17"
                  height="16"
                  viewBox="0 0 17 16"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.3288 5.19213C13.3331 5.1549 13.3331 5.11713 13.3288 5.0799C13.3304 5.06695 13.3304 5.054 13.3288 5.04105C13.3083 4.9941 13.2808 4.9504 13.2468 4.91155L9.79342 1.45819C9.75457 1.4242 9.71087 1.39668 9.66392 1.37617H9.62507L9.47398 1.33301H5.12704C4.41208 1.33301 3.83203 1.91306 3.83203 2.62802V12.9881C3.83203 13.703 4.41208 14.2831 5.12704 14.2831H12.0337C12.7487 14.2831 13.3288 13.703 13.3288 12.9881V5.21803V5.19213H13.3288ZM11.8568 4.78636H10.3071C10.0686 4.78636 9.87544 4.59319 9.87544 4.35469V2.80497L11.8568 4.78636ZM12.0338 13.4197H5.12711C4.88861 13.4197 4.69544 13.2266 4.69544 12.9881V2.62802C4.69544 2.38951 4.88861 2.19635 5.12711 2.19635H9.01213V4.35469C9.01213 5.06965 9.59218 5.6497 10.3071 5.6497H12.4655V12.9881C12.4655 13.1025 12.4202 13.2125 12.3392 13.2935C12.2583 13.3744 12.1482 13.4197 12.0338 13.4197L12.0338 13.4197Z"
                    fill="currentColor"
                  />
                </svg>
                File
              </a>
              <p v-else class="w-full text-center">-</p>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
/* eslint-disable @typescript-eslint/camelcase */
import { defineComponent } from 'vue'
import { Classroom } from '@/dto'
import ClassroomModel from '@/models/ClassroomModel'

export default defineComponent({
  name: 'Tanya Mentor',
  data() {
    return {
      classroomCode: this.$route.params.classroom_code as string,
      classroom: {} as Classroom,
      paginationQuestion: {
        perPage: 10 as number,
        lastPage: 2 as number,
        total: 0 as number,
        page: 1 as number,
        from: 0 as number,
        to: 0 as number,
      },
      questions: [] as any,
      isLoading: false,
    }
  },
  mounted() {
    this.getUserQuestion()
  },
  methods: {
    async getUserQuestion() {
      this.isLoading = true
      await ClassroomModel.getUserQuestionMentor(
        this.classroomCode,
        this.paginationQuestion
      )
        .then(res => {
          this.isLoading = false
          this.questions = res.data
          this.paginationQuestion.perPage = res.pages.per_page
          this.paginationQuestion.page = res.pages.current_page
          this.paginationQuestion.lastPage = Number(res.pages.last_page)
          this.paginationQuestion.total = res.pages.total
          this.paginationQuestion.from = res.pages.from
          this.paginationQuestion.to = res.pages.to
        })
        .catch(err => {
          this.$router.push('/mentor/classroom')
          this.isLoading = false
        })
    },
    onPage(event: any) {
      this.paginationQuestion.page = event.page + 1
      this.paginationQuestion.perPage = event.rows
      this.getUserQuestion()
    },
  },
})
</script>

<style scoped>
.tab {
  padding: 10px 20px;
}
.border-b-blue {
  border-bottom: 3px solid #009dff;
}
::v-deep(.p-column-title) {
  font-family: 'SolusiSans';
  font-weight: 700;
}
::v-deep(.p-paginator-current) {
  font-family: 'SolusiSans';
  font-weight: 600;
}
::v-deep(.p-paginator .p-paginator-pages .p-paginator-page) {
  font-family: 'SolusiSans';
  font-weight: 500;
}
</style>
