
/* eslint-disable @typescript-eslint/camelcase */
import { defineComponent } from 'vue'
import { Classroom } from '@/dto'
import ClassroomModel from '@/models/ClassroomModel'

export default defineComponent({
  name: 'Tanya Mentor',
  data() {
    return {
      classroomCode: this.$route.params.classroom_code as string,
      classroom: {} as Classroom,
      paginationQuestion: {
        perPage: 10 as number,
        lastPage: 2 as number,
        total: 0 as number,
        page: 1 as number,
        from: 0 as number,
        to: 0 as number,
      },
      questions: [] as any,
      isLoading: false,
    }
  },
  mounted() {
    this.getUserQuestion()
  },
  methods: {
    async getUserQuestion() {
      this.isLoading = true
      await ClassroomModel.getUserQuestionMentor(
        this.classroomCode,
        this.paginationQuestion
      )
        .then(res => {
          this.isLoading = false
          this.questions = res.data
          this.paginationQuestion.perPage = res.pages.per_page
          this.paginationQuestion.page = res.pages.current_page
          this.paginationQuestion.lastPage = Number(res.pages.last_page)
          this.paginationQuestion.total = res.pages.total
          this.paginationQuestion.from = res.pages.from
          this.paginationQuestion.to = res.pages.to
        })
        .catch(err => {
          this.$router.push('/mentor/classroom')
          this.isLoading = false
        })
    },
    onPage(event: any) {
      this.paginationQuestion.page = event.page + 1
      this.paginationQuestion.perPage = event.rows
      this.getUserQuestion()
    },
  },
})
